import { useEffect, useRef } from 'react'

const checkRequestAnimationFrame = (() => {
  let cached: boolean | undefined
  return () => {
    if (cached !== undefined) {
      return cached
    }
    // ssr
    if (typeof window === 'undefined') {
      return false
    }
    cached = window.requestAnimationFrame !== undefined
    return cached
  }
})()

const RAFThrottle = (func: Function) => {
  function frame() {
    requestId = null
    func(...lastArgs!)
    lastArgs = null
  }
  let lastArgs: unknown[] | null
  let requestId: number | NodeJS.Timeout | null

  const throttled = (...args: unknown[]) => {
    lastArgs = args
    if (requestId == null) {
      requestId = checkRequestAnimationFrame()
        ? requestAnimationFrame(frame)
        : setTimeout(frame, 20)
    }
  }

  throttled.cancel = () => {
    if (requestId != null) {
      if (checkRequestAnimationFrame()) {
        cancelAnimationFrame(requestId as number)
      } else {
        clearTimeout(requestId as NodeJS.Timeout)
      }
      requestId = null
    }
  }

  return throttled
}

interface IScrollingOptions {
  callback: () => void
  scrollDistance: number
  enabled?: boolean
}

const useScrollingCallback = (options: IScrollingOptions) => {
  const startPosition = useRef(0)
  const stableFunctionRef = useRef(options.callback)
  stableFunctionRef.current = options.callback

  const enabled = options.enabled ?? true

  useEffect(() => {
    if (enabled) {
      startPosition.current = window.scrollY
    }
  }, [options.enabled, enabled])

  useEffect(() => {
    if (!enabled) {
      return
    }

    const observeScrollPosition = RAFThrottle(() => {
      if (window.scrollY - startPosition.current > options.scrollDistance) {
        stableFunctionRef.current()
      }
    })

    window.addEventListener('scroll', observeScrollPosition)
    return () => {
      window.removeEventListener('scroll', observeScrollPosition)
      observeScrollPosition.cancel()
    }
  }, [enabled, options.enabled, options.scrollDistance])
}

export default useScrollingCallback
