'use client'

import Link from 'component/block/link'
import type { MutableRefObject } from 'react'
import Thumbnail, {
  MediaThumbnailTypeEnum
} from 'component/block/thumbnail/thumbnail'
import cn from 'util/cn'

export enum MediaFeedTypeEnum {
  Hashtag = 'hashtag',
  Place = 'place',
  Profile = 'profile',
  Search = 'search',
  Community = 'community'
}

export interface IMediaThumbnailProp {
  coverUrl: string
  desc: string
  ref?: MutableRefObject<HTMLAnchorElement>
  thumbnailType?: MediaThumbnailTypeEnum
  mediaFeedTypeType: MediaFeedTypeEnum
  className?: string
  linkId?: string
  onClick?: () => void
}

const SmallMediaThumbnailLink = ({
  ref,
  coverUrl,
  desc,
  thumbnailType = MediaThumbnailTypeEnum.Image,
  mediaFeedTypeType,
  className,
  linkId,
  onClick
}: IMediaThumbnailProp) => {
  return (
    <Link
      ref={ref}
      href={
        linkId
          ? `/media/${mediaFeedTypeType}/${linkId}`
          : `/media/${mediaFeedTypeType}`
      }
      className={cn(
        'relative  w-full min1080:h-[285px] min560:h-[228px] h-[188px] overflow-hidden',
        className
      )}
      onClick={onClick}
    >
      <Thumbnail url={coverUrl} desc={desc} thumbnailType={thumbnailType} />
    </Link>
  )
}

export default SmallMediaThumbnailLink
