import * as TabsPrimitive from '@radix-ui/react-tabs'

import { twc, type TwcComponentWithAsChild } from 'util/cn'

export const Tab = TabsPrimitive.Root

export const TabList = twc(
  TabsPrimitive.List
)`sticky top-[--tab-list-top] w-full bg-background-1st h-[40px] shadow-[inset_0_-1px_0_0_var(--glass-special-separators)] flex z-[2]`

type TabTriggerProps = TwcComponentWithAsChild<typeof TabsPrimitive.Trigger>
export const TabTrigger = twc(TabsPrimitive.Trigger).attrs<TabTriggerProps>(
  props => ({
    asChild: props.$asChild
  })
)`shrink-0 flex items-center justify-center border-b-[1px] text-label-l2 font-semibold data-[state=active]:border-label-l1 data-[state=active]:text-label-l1 text text-[3vw] min560:text-md`

export const TabContent = TabsPrimitive.Content
