import { useEffect, useRef, type PropsWithChildren } from 'react'

import LoadingSpinner from 'component/block/loading/spinner'

interface IScrollLoad {
  callback: () => void
  fetching: boolean
  fetchEnd: boolean
  observerConfig?: object
  style?: React.CSSProperties
  loader?: JSX.Element
  className?: string
}

const ScrollLoad = ({
  callback,
  fetchEnd,
  fetching,
  observerConfig,
  style,
  loader,
  children,
  className
}: PropsWithChildren<IScrollLoad>): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!fetchEnd) {
      let config: IntersectionObserverInit = {
        threshold: 0.5,
        rootMargin: '0px 0px 1200px 0px'
      }
      if (observerConfig) {
        config = { ...config, ...observerConfig }
      }
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries[0].intersectionRatio <= 0) return
        if (fetching) return
        callback()
      }, config)

      ref.current && intersectionObserver.observe(ref.current)

      return () => intersectionObserver.disconnect()
    }
  }, [fetchEnd, callback, observerConfig, fetching])

  return (
    <div className={className} style={style}>
      {children}
      {!fetchEnd && (
        <div ref={ref} data-testid='scroll-loading'>
          {loader || (
            <div className='relative h-[100px]'>
              <LoadingSpinner />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ScrollLoad
