import React from 'react'
import reactStringReplace from 'react-string-replace'

export interface ISearchReplace {
  search: string | RegExp
  replace: (match: string, index: number) => React.ReactNode
}

interface IStringReplace {
  (str: string, searchReplaces: ISearchReplace[]): React.ReactNode
}
const StringReplace: IStringReplace = (str, searchReplaces) => {
  if (!str) return []

  let result: React.ReactNode[] = [str]
  for (let i = 0; i < searchReplaces.length; i++) {
    const { search, replace } = searchReplaces[i]
    result = reactStringReplace(result, search, (match, index) => {
      const replacement = replace(match, index)
      return React.isValidElement(replacement)
        ? React.cloneElement(replacement, { key: `${search}-${index}` })
        : replacement
    })
  }

  return result
}

export default StringReplace
