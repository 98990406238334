'use client'

import React, { type MouseEvent } from 'react'
import { useRouter } from 'lib/next-intl'
import { useTranslations } from 'next-intl'
import stringReplace from 'util/stringReplace'
import { SharedFromTypeEnum } from 'type/comment'

interface IShareFromAny {
  type: SharedFromTypeEnum
  profile?: { uid?: string; name?: string } | null
  community?: { id: string; title: string }
}

const ShareFromAny = ({ profile, community, type }: IShareFromAny) => {
  const t = useTranslations()
  const router = useRouter()
  const name =
    type === SharedFromTypeEnum['community'] ? community?.title : profile?.name

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (!profile?.uid && !community?.id) return

    switch (type) {
      case SharedFromTypeEnum['community']:
        router.push(`/community/${community?.id}`)
        break
      case SharedFromTypeEnum['profile']:
        router.push(`/${profile?.uid}`)
        break
    }
  }

  return (
    <div onClick={handleClick}>
      {stringReplace(t('shared_from_community', { name }), [
        { search: name || '', replace: m => <b>{m}</b> }
      ])}
    </div>
  )
}

export default ShareFromAny
