import Comment from 'component/block/comment/comment'
import CommentTitle from 'component/block/comment/comment-title'
import Content from 'component/block/comment/content'
import InteractiveTools from 'component/block/comment/interactive-tools'
import ShareFromAny from 'component/block/comment/share-from-any'
import { isCommentMedia } from 'type/media'
import type { FullComment } from 'util/comment'
import type { Nullable } from 'vitest'

interface ICommunityPreview {
  comment: {
    comment: FullComment
    subComments: Nullable<FullComment[]>
  }
  isSubComment?: boolean
  isCommunityPageLink?: boolean
}

const CommunityWithComments = ({
  comment: communityComment,
  isCommunityPageLink,
  isSubComment = true
}: ICommunityPreview) => {
  if (!communityComment) {
    return null
  }
  const { comment, subComments = [] } = communityComment
  const commentId = comment.id || comment.comment_id || ''
  const sharedFromProfileOwner = isCommentMedia(comment.media?.[0])
    ? comment.media?.[0].comment.user
    : undefined
  return (
    <div className='block border-b-[2px] border-glass_special-separators pb-[16px] cursor-pointer'>
      <Comment
        title={
          comment?.parent_title && (
            <CommentTitle
              communityId={comment.parent_id || ''}
              title={comment?.parent_title || ''}
            />
          )
        }
        className='pt-[16px]'
        user={comment.user}
        connectBottom={!!subComments?.length && isSubComment}
        communityId={comment.parent_id}
        commentId={commentId}
        asLink
        isCommunityPageLink={isCommunityPageLink}
      >
        <Content
          comment={comment.text}
          user={comment.user}
          commentTime={comment.created_at}
          media={comment.media}
          place={comment.place}
          interactiveTools={
            <InteractiveTools
              commentId={commentId}
              isLiked={comment.user_is_like || false}
              likeCount={comment.counts.like}
              shareCount={comment.counts.share}
              replyCount={comment.counts.comment || 0}
            />
          }
          shareFrom={
            comment.shared_from && (
              <div className=' mb-2 mt-1 min-w-0 truncate text-label-l2'>
                <ShareFromAny
                  type={comment.shared_from.type}
                  profile={sharedFromProfileOwner}
                  community={comment.shared_from.community}
                />
              </div>
            )
          }
        />
      </Comment>

      {isSubComment &&
        subComments?.map((sub, index) => (
          <Comment
            key={`${sub.id}-${sub.created_at}`}
            connectTop={true}
            connectBottom={index !== subComments.length - 1}
            user={sub.user}
          >
            <Content
              comment={sub.text}
              user={sub.user}
              commentTime={sub.created_at}
              media={sub.media}
              interactiveTools={
                <InteractiveTools
                  commentId={sub.id || ''}
                  isLiked={sub.user_is_like || false}
                  likeCount={sub.counts.like}
                  shareCount={sub.counts.share}
                  replyCount={comment.counts.comment || 0}
                />
              }
            />
          </Comment>
        ))}
    </div>
  )
}

export default CommunityWithComments
