import { type FullComment } from 'util/comment'
import { create } from 'zustand'

interface IInitialMediaStoreState {
  value: {
    initComment?: FullComment
    initNearbyComment?: FullComment
  }
}

interface IInitialMediaStoreAction {
  setInitComment: (comment: FullComment) => void
  setInitNearbyComment: (comment: FullComment) => void
  clearInit: () => void
}

export const useInitialMediaStore = create<
  IInitialMediaStoreState & IInitialMediaStoreAction
>((set, get) => ({
  value: {},
  setInitComment: comment => {
    set({ value: { ...get().value, initComment: comment } })
  },
  setInitNearbyComment: comment => {
    set({ value: { ...get().value, initComment: comment } })
  },
  clearInit: () => {
    set({ value: {} })
  }
}))
