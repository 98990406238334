import React, { useEffect } from 'react'
import { userPausedStore } from 'store/global/video'

const useKeydownToggleVideo = () => {
  useEffect(() => {
    // prevent space key scroll
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === ' ' && e.target === document.body) {
        e.preventDefault()
      }
    }
    function detectKeyDown(e: KeyboardEvent) {
      if (e.key === ' ') {
        userPausedStore.toggle()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keydown', detectKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keydown', detectKeyDown)
    }
  }, [])
}

export default useKeydownToggleVideo
