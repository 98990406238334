'use client'

import React from 'react'
import cn from 'util/cn'
import { useRouter } from 'lib/next-intl'

interface ICommentTitleProps {
  title: string
  className?: string
  communityId?: string
}

const CommentTitle = ({
  communityId,
  className,
  title
}: ICommentTitleProps) => {
  const router = useRouter()

  return (
    <div
      className={cn(
        'flex items-center bg-background-1st pl-[16px] text-md font-semibold text-label-l2 mb-[4px]',
        className
      )}
      onClick={e => {
        e.stopPropagation()
        communityId && router.push(`/community/${communityId}`)
      }}
    >
      <div
        className='i-ps-group_f [--icon-size:14px] shrink-0 mr-[2px]'
        role='img'
        aria-label='Community'
      />
      <span className='min-w-0 truncate max-w-[--content-size]'>{title}</span>
    </div>
  )
}

export default CommentTitle
