const calculateMainLeft = () => {
  const mainLayout = document.querySelector('.playsee-main')
  if (mainLayout) {
    const { left, right } = mainLayout.getBoundingClientRect()
    return `${(left + right) / 2}px`
  }

  return `calc(50% + var(--side-left-width))`
}

export default calculateMainLeft
