import { useTranslations } from 'next-intl'
import { type ComponentPropsWithoutRef } from 'react'
import cn from 'util/cn'

interface INoActivity extends ComponentPropsWithoutRef<'div'> {
  uid?: string
}

const NoActivity = ({ uid, ...props }: INoActivity) => {
  const t = useTranslations()

  return (
    <div
      {...props}
      className={cn(
        'flex flex-col items-center justify-center',
        props.className
      )}
    >
      <p className='text-label-l1 text-callout mb-1'>
        {t('profile.no_posts_yet')}
      </p>
      <p className='text-md text-label-l2'>
        {uid
          ? t('profile.no_post_yet', { name: uid })
          : t('profile.no_posts_yet')}
      </p>
    </div>
  )
}

export default NoActivity
