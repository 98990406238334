import { cva } from 'class-variance-authority'
import cn from 'util/cn'

const spinnerVariants = cva(
  [
    'absolute left-1/2 top-1/2',
    'animate-spinner-rotate rounded-full border-playseeGray-gray4 border-l-transparent'
  ],
  {
    variants: {
      $size: {
        sm: 'h-[14px] w-[14px] border-[3px]',
        md: 'h-[24px] w-[24px] border-[4px]'
      }
    },
    defaultVariants: {
      $size: 'md'
    }
  }
)

interface ILoading {
  text?: string
  size?: 'sm' | 'md'
}
const LoadingSpinner = ({ text, size }: ILoading): JSX.Element => {
  return (
    <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-[16px]'>
      <div className={cn(spinnerVariants({ $size: size }))} />
      {text && <span className='mt-[8px] text-md text-label-l2'>{text}</span>}
    </div>
  )
}

export default LoadingSpinner
