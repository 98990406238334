'use client'

import { Presence } from '@radix-ui/react-presence'
import { useEffect, useState } from 'react'

import IconArrowUp from 'asset/icon/arrow_up.svg'
import { Button } from 'component/ui/button'
import calculateMainLeft from 'util/calculateMainLeft'
import cn from 'util/cn'
import scrollTop from 'util/scrollTop'

const GoTopButton = () => {
  const [isGoTopButtonShow, setIsGoTopButtonShow] = useState(false)
  const [left, setLeft] = useState('0px')

  useEffect(() => {
    const handleResize = () => {
      const left = calculateMainLeft()
      setLeft(left)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY
      const threshold = 400

      if (scrollY > threshold) {
        setIsGoTopButtonShow(true)
      } else {
        setIsGoTopButtonShow(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Presence present={isGoTopButtonShow}>
      <Button
        $variant='text-still'
        $size='lg'
        $icon={true}
        $radius='round'
        onClick={scrollTop}
        style={
          {
            '--left': `${left}`
          } as React.CSSProperties
        }
        className={cn(
          'fixed bottom-[24px] left-[--left] z-header -translate-x-1/2 transform bg-floating text-label-l1 shadow-floating-button',
          'duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:slide-out-to-left-1/2 data-[state=open]:slide-in-from-left-1/2'
        )}
        data-state={isGoTopButtonShow ? 'open' : 'closed'}
      >
        <IconArrowUp className='h-[24px] w-[24px]' />
      </Button>
    </Presence>
  )
}

export default GoTopButton
